const ru = {
  belvodputGomel: "Белводпуть-Гомель",
  belvodputGrodno: "Белводпуть-Гродно",

  bankProtectionService: "Берегоукрепительные работы",
  trackComplexService: "Выполнение комплекса путевых работ",
  constructionService: "Производство строительных работ",

  seeMore: "Подробнее",
  hide: "Скрыть",

  landscapeWise: "Учет особенностей ландшафта",
  usageOfFlexibleConcreteMats: "Применение гибких бетонных матов",
  geoGridApplication: "Применение георешетки",

  scooping: "Землечерпание",
  bottomCleaning: "Дноочищение",
  dredging: "Дноуглубление",
  navigationMaintenance: "Содержание навигационного оборудования",
  engineeringAndGeodeticSurveys: "Инженерно-геодезические изыскания",
  rentOf14tTruckCrane: "Аренда автокрана 14 т",
  frontLoaderRental: "Аренда фронтального погрузчика",

  waterManagementConstruction: "Водохозяйственное строительство",
  hydromechanizedExcavation: "Гидромеханизированная разработка грунта",
  transportationOfGoodsAndPassengers: "Перевозка грузов и пассажиров",
  dredgingDescription: "К дноуглублению относятся работы, выполняемые для углубления, расширения, спрямления и поддержания состояния существующих судовых ходов с установленными габаритами, а также с целью создания новых судовых ходов. Складирование извлекаемого грунта производится в русле реки (водоёма) или на береговой полосе.",
  dredgingUsageInfo: "Дноуглубление применяется при разработке грунта из-под воды с укладкой на берег в карты намыва для возведения насыпей при строительстве гидротехнических сооружений. Для производства вышеприведенных работ на предприятии имеются три землесосных земснаряда. Производительностью - 325м3/час, глубина разработки свыше 12 метров, дальность рефулирования свыше 600 метров. Один земснаряд является сборно-разборным и перевозится в закрытые водоёмы. Для разработки «тяжелых» и каменистых грунтов имеется плавучий кран, грузоподъёмностью 5 тонн.",

  aboutCompany: "О предприятии",
  companyDescription: `Республиканское Унитарное Днепро-Двинское предприятие Водных Путей "Белводпуть" находится в Гомеле и расположено по адресу улица Коммунаров, 7а. Предприятие создано для обслуживания внутренних водных путей, с целью обеспечения безопасных условий судоходства на водных путях Республики Беларусь. Чтобы связаться с сотрудниками организации, вы можете позвонить по телефону +375 232 50-66-46 и уточнить у них любую интересующую вас информацию.`,
  grodnoCompanyDescription: `Республиканское Унитарное Днепро-Двинское предприятие Водных Путей "Белводпуть" находится в Гродно и расположено по адресу ул. Портовая 5. Предприятие создано для обслуживания внутренних водных путей (река Неман, Августовский канал), с целью обеспечения безопасных условий судоходства на водных путях Республики Беларусь. Чтобы связаться с сотрудниками организации, вы можете позвонить по телефону +375 015 239-34-56 и уточнить у них любую интересующую вас информацию.`,

  ourPartners: "Наши партнеры",

  aboutAugustowChannel: "Об Августовском канале",
  augustowChannelDescription: `Августовский канал – один из крупнейших в Европе. Построен в XIX веке для сплава древесины и товаров, соединяет бассейны рек Вислы и Немана. Часть этого гидротехнического сооружения проходит по территории Польши (80 км), часть – по Беларуси (22 км) в Гродненской области. Свои основные функции канал выполнял недолго. Но благодаря красивейшим пейзажам, через которые он проходит, стал популярным туристическим местом. Войны XX века не пощадили рукотворную водную артерию. Однако в 2004 – 2006 годах ее белорусский участок был отреставрирован. Сегодня Августовский канал снова принимает туристов и является отличным местом для активного, да и для любого другого, отдыха.`,

  Gomel: "Гомель",
  Grodno: "Гродно",

  belvodput: `РУДДПВП "Белводпуть"`,
  services: "Услуги",
  partners: "Партнеры",
  contacts: "Контакты",

  news: "Новости",

  alluvium: "Намыв грунта",

  address: "Адрес",
  email: "Почта",
  phoneNumbers: "Телефоны",

  ministry: "Министерство транспорта и коммуникаций",
  ticketSystem: "Система обращения граждан",

  sections: "Разделы",
  main: "Главная",

  companyAddress: "Беларусь, 246050 г.Гомель, ул. Коммунаров, 7а",

  chiefOfficer: "директор",
  chiefEngineer: "главный инженер",
  chiefOfficerDeputy: "зам. директора",
  receptionRoom: "приемная",
  accountant: "бухгалтер",

  grodnoCompanyAddress: "Беларусь, 230011, г. Гродно, ул. Портовая 5",

  grodnoChiefOfficerDeputy: "зам. начальника филиала по производству",
  cargoSectorMaster: "мастер грузового участка",
  leadTourismSpecialist: "ведущий специалист по туризму",
};

export default ru;